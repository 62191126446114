import React from 'react';
import { Link } from 'react-router-dom';
import { encryptId, formatPrice, KTSVG } from '../../../../_metronic/helpers';
import { formattedCurrency } from '../../../../_metronic/helpers/FormatCurrency';
import { TableList } from '../../../../_metronic/helpers/components/TableList';
import { EmptyResult } from '../../../../_metronic/helpers/components/EmptyResult';
import { restar, sumar } from '../../../../_metronic/helpers/PriceCalculations';

type Props = {
  data: any;
  loadingGrid: boolean;
};
const ListGrid: React.FC<Props> = ({ data, loadingGrid }) => {
  const calculateSubtotal = (
    subtotal: number,
    surcharge: number,
    discount: number,
    indirectos: number,
    utilidad: number
  ) => {
    let totalConSurcharge = sumar(subtotal, surcharge);
    let totalConDescuento = restar(totalConSurcharge, discount);
    let totalConIndirectos = sumar(totalConDescuento, indirectos);
    let total = sumar(totalConIndirectos, utilidad);
    return total;
  };

  const columns: any = [
    {
      id: 'nombre',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.nombre,
      cell: (row: any) => (
        <Link
          className="large-text"
          to={`/cotizaciones/edit/${encryptId(row.id.toString())}`}
        >
          {row.nombre}
        </Link>
      ),
      sortable: true,
    },
    {
      id: 'cuenta',
      name: <div className="fw-bolder text-muted me-5">Cuenta</div>,
      selector: (row: any) => row?.cuenta?.nombre,
      cell: (row: any) => (
        <div className="text-gray-700 large-text">
          {row?.cuenta ? row?.cuenta?.nombre : 'Sin cuenta'}
        </div>
      ),
      sortable: true,
    },
    {
      name: <div className="fw-bolder text-muted me-5">Vendedor</div>,
      selector: (row: any) => row?.vendedorAsignado?.nombre,
      cell: (row: any) => (
        <>
          <div className="symbol symbol-30px tooltip-soft">
            {row.vendedorAsignado ? (
              <>
                {row.vendedorAsignado.user?.profile_photo ? (
                  <>
                    <img
                      src={`${process.env.REACT_APP_PUBLIC}users/${row?.vendedorAsignado.user?.profile_photo}`}
                      crossOrigin="anonymous"
                      className="symbol-label fs-8 "
                      alt="user"
                    />
                    <span className="tooltiptext">{`${row?.vendedorAsignado?.nombre} ${row?.vendedorAsignado?.apellido_paterno}`}</span>
                  </>
                ) : (
                  <>
                    <span className="symbol-label fs-8 fw-bold bg-light-primary text-primary fw-bold">
                      {`${row?.vendedorAsignado?.nombre[0]}${row?.vendedorAsignado?.apellido_paterno[0]}`}
                    </span>
                    <span className="tooltiptext">{`${row?.vendedorAsignado?.nombre} ${row?.vendedorAsignado?.apellido_paterno}`}</span>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="symbol-label">
                  <KTSVG
                    path="/media/icons/duotune/communication/com006.svg"
                    className="svg-icon-1"
                  />
                </div>
                <span className="tooltiptext">Sin vendedor</span>
              </>
            )}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      id: 'version',
      name: <div className="fw-bolder text-muted me-5">Versión</div>,
      selector: (row: any) =>
        row.versiones ? row.versiones.numero_version : 'R1',
      cell: (row: any) => (
        <>
          <div className={`mt-2 badge badge-light-success`}>
            {row.versiones ? row.versiones.numero_version : 'R1'}
          </div>
        </>
      ),
      sortable: false,
    },
    {
      id: 'precio_venta',
      name: <div className="fw-bolder text-muted me-5">Precio venta</div>,
      selector: (row: any) => row.subtotal,
      cell: (row: any) => (
        <>
          <div>
            <span className="text-gray-700 fw-bolder">
              {`${formatPrice(
                calculateSubtotal(
                  Number(row.subtotal),
                  Number(row.monto_recargo),
                  Number(row.monto_descuento),
                  Number(row.monto_costo_indirecto),
                  Number(row.monto_utilidad)
                ),
                'es-MX',
                'MXN',
                2,
                2
              )}`}
            </span>
            <span className="text-gray-600 ms-3 fs-8">
              {row.unidadMoneda
                ? `/ ${row.unidadMoneda.code}`
                : 'Sin unidad de moneda'}
            </span>
          </div>
        </>
      ),
      sortable: true,
    },
    // {
    //   id: 'monto_recargo',
    //   name: <div className="fw-bolder text-muted me-5">Recargo</div>,
    //   selector: (row: any) => row.monto_recargo,
    //   cell: (row: any) => (
    //     <>
    //       <div>
    //         <span className="text-gray-700 fw-bolder">
    //           {`${formatPrice(
    //             Number(row.monto_recargo) || 0,
    //             'es-MX',
    //             'MXN',
    //             2,
    //             2
    //           )}`}
    //         </span>
    //         <span className="text-gray-600 ms-3 fs-8">
    //           {row.unidadMoneda
    //             ? `/ ${row.unidadMoneda.code}`
    //             : 'Sin unidad de moneda'}
    //         </span>
    //       </div>
    //     </>
    //   ),
    //   sortable: true,
    // },
    // {
    //   id: 'monto_descuento',
    //   name: <div className="fw-bolder text-muted me-5">Descuento</div>,
    //   selector: (row: any) => row.monto_descuento,
    //   cell: (row: any) => (
    //     <>
    //       <div>
    //         <span className="text-gray-700 fw-bolder">
    //           {`${formatPrice(
    //             Number(row.monto_descuento) || 0,
    //             'es-MX',
    //             'MXN',
    //             2,
    //             2
    //           )}`}
    //         </span>
    //         <span className="text-gray-600 ms-3 fs-8">
    //           {row.unidadMoneda
    //             ? `/ ${row.unidadMoneda.code}`
    //             : 'Sin unidad de moneda'}
    //         </span>
    //       </div>
    //     </>
    //   ),
    //   sortable: true,
    // },
    // {
    //   id: 'monto_costo_indirecto',
    //   name: <div className="fw-bolder text-muted me-5">Costo indirecto</div>,
    //   selector: (row: any) => row.monto_costo_indirecto,
    //   cell: (row: any) => (
    //     <>
    //       <div>
    //         <span className="text-gray-700 fw-bolder">
    //           {`${formatPrice(
    //             Number(row.monto_costo_indirecto) || 0,
    //             'es-MX',
    //             'MXN',
    //             2,
    //             2
    //           )}`}
    //         </span>
    //         <span className="text-gray-600 ms-3 fs-8">
    //           {row.unidadMoneda
    //             ? `/ ${row.unidadMoneda.code}`
    //             : 'Sin unidad de moneda'}
    //         </span>
    //       </div>
    //     </>
    //   ),
    //   sortable: true,
    // },
    // {
    //   id: 'monto_utilidad',
    //   name: <div className="fw-bolder text-muted me-5">Utilidad</div>,
    //   selector: (row: any) => row.monto_utilidad,
    //   cell: (row: any) => (
    //     <>
    //       <div>
    //         <span className="text-gray-700 fw-bolder">
    //           {`${formatPrice(
    //             Number(row.monto_utilidad) || 0,
    //             'es-MX',
    //             'MXN',
    //             2,
    //             2
    //           )}`}
    //         </span>
    //         <span className="text-gray-600 ms-3 fs-8">
    //           {row.unidadMoneda
    //             ? `/ ${row.unidadMoneda.code}`
    //             : 'Sin unidad de moneda'}
    //         </span>
    //       </div>
    //     </>
    //   ),
    //   sortable: true,
    // },
    {
      id: 'impuesto',
      name: <div className="fw-bolder text-muted me-5">Impuesto</div>,
      selector: (row: any) => row.monto_impuestos,
      cell: (row: any) => (
        <>
          <div>
            <span className="text-gray-700 fw-bolder">
              {`${formatPrice(
                Number(row.monto_impuestos) || 0,
                'es-MX',
                'MXN',
                2,
                2
              )}`}
            </span>
            <span className="text-gray-600 ms-3 fs-8">
              {row.unidadMoneda
                ? `/ ${row.unidadMoneda.code}`
                : 'Sin unidad de moneda'}
            </span>
          </div>
        </>
      ),
      sortable: true,
    },
    {
      id: 'precio_final_venta',
      name: <div className="fw-bolder text-muted me-5">Precio venta final</div>,
      selector: (row: any) => row.total,
      cell: (row: any) => (
        <>
          <div>
            <span className="text-gray-700 fw-bolder">
              {`${formatPrice(Number(row.total) || 0, 'es-MX', 'MXN', 2, 2)}`}
            </span>
            <span className="text-gray-600 ms-3 fs-8">
              {row.unidadMoneda
                ? `/ ${row.unidadMoneda.code}`
                : 'Sin unidad de moneda'}
            </span>
          </div>
        </>
      ),
      sortable: true,
    },
    // {
    //   id: 'tamano',
    //   name: <div className="fw-bolder text-muted me-5 w-100">Monto</div>,
    //   selector: (row: any) => row.total,
    //   cell: (row: any) => (
    //     <div className="fw-bold text-gray-700">
    //       {formattedCurrency(parseFloat(row.total))}
    //     </div>
    //   ),
    //   sortable: true,
    // },
  ];

  return (
    <>
      <div className={`row`}>
        <div className="col-xl-12">
          <div className={`card mb-0 mb-xxl-8 h-100 w-100`}>
            {!loadingGrid ? (
              <TableList data={data} columns={columns}></TableList>
            ) : (
              <EmptyResult title="" loading={loadingGrid} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
