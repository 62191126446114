import React, { useState, useEffect } from 'react';
import {
  KTSVG,
  SimpleAlert,
  encryptId,
  formatPrice,
} from '../../../../../_metronic/helpers';
import { MenuComponent } from '../../../../../_metronic/assets/ts/components';
import { Link } from 'react-router-dom';
import { dateFormatt } from '../../../../../_metronic/helpers/FormatDate';
import { VersionModel } from '../../models/QuotationModel';
import { updateActiveVenta } from '../../../../service/quotation/quotation';

type Props = {
  cotizacion_id: number;
  data: VersionModel[];
  eliminarVersion: (version_id: number) => void;
  recargarGrid: () => void;
};

const VersionList: React.FC<Props> = ({
  cotizacion_id,
  data,
  eliminarVersion,
  recargarGrid,
}) => {
  const [dataMemo, setDataMemo] = useState<VersionModel[]>(data);
  useEffect(() => {
    setDataMemo(data);
    MenuComponent.reinitialization();
  }, [data]);

  const seleccionarCotizacion = (item: any, is_venta: boolean) => {
    const { id } = item;
    if (id > 0 && !is_venta) {
      updateActiveVenta(id)
        .then((res) => {
          const {
            data: { message },
          } = res;
          recargarGrid();
          SimpleAlert(message, 3000, 'success');
        })
        .catch((err) => {
          let resMessageToast: string = '';
          const {
            data: { message, details = null },
          } = err.response;
          resMessageToast = `${message}`;
          SimpleAlert(resMessageToast, 3000, 'error');
        });
    }
  };

  const dropdownRender = (id: number) => {
    return (
      <>
        <div className="space-items-between d-flex flex-shrink-0">
          <div className="mr-05">
            <div
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="bottom"
              data-kt-menu="true"
              className="btn btn-sm btn-icon btn-active-light-primary btn-light-primary center-items"
            >
              <KTSVG
                path="/media/icons/duotune/abstract/abs011.svg"
                className="svg-icon-8"
              />
            </div>
            <div
              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4"
              data-kt-menu="true"
            >
              <div className="menu-item px-3 center-items">
                <div
                  className="menu-link px-3 fs-8"
                  onClick={() => {
                    window.location.href = `/cotizaciones/edit/${encryptId(
                      id.toString()
                    )}`;
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen055.svg"
                    className="svg-icon-5 svg-icon-gray-500 mr-05"
                  />
                  <span> Editar</span>
                </div>
              </div>
              <div className="menu-item px-3 center-items">
                <div
                  className="menu-link px-3 fs-8"
                  onClick={() => {
                    eliminarVersion(id);
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen027.svg"
                    className="svg-icon-5 svg-icon-gray-500 mr-05"
                  />
                  Borrar
                </div>
              </div>
              <div className="menu-item px-3 center-items">
                <Link
                  className="menu-link px-3 fs-8"
                  to={`/cotizaciones/preview/pdf/${encryptId(id.toString())}`}
                >
                  <KTSVG
                    path="/media/icons/duotune/general/pdf-ic.svg"
                    className="svg-icon-5 svg-icon-gray-500 mr-05"
                  />
                  Visualizar el PDF
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-100px">Venta</th>
              <th className="w-100px">Versión</th>
              <th className="w-100px">Fecha </th>
              <th className="w-100px">Monto total</th>
              <th className="w-150px">Autor</th>
              <th className="w-50px">Acciones</th>
            </tr>
          </thead>
          <tbody className="">
            {dataMemo.map((row, index) => (
              <tr key={row.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex ">
                      <label className="form-check form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={row.venta}
                          onClick={async () => {
                            seleccionarCotizacion(row, row.venta);
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012">
                      <div className={`mt-2 badge badge-light-success`}>
                        {' '}
                        {row.version}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.fecha ? dateFormatt(row.fecha) : row.fecha}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {`${formatPrice(row.total || 0, 'es-MX', 'MXN', 2, 2)}`}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.autor}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.id === cotizacion_id ? (
                        <>
                          <div className={`mt-2 badge badge-light-info`}>
                            {' Actual '}
                          </div>
                        </>
                      ) : (
                        <>{dropdownRender(row.id)}</>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export { VersionList };
