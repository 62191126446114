import React, { useState, useEffect } from 'react';
import { KTSVG, SimpleAlert } from '../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGetOne } from '../hooks/ConditionsHook';
import { NumericFormat } from 'react-number-format';
import {
  addValueConditions,
  updValueConditions,
} from '../../../service/conditions/Conditions';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);

  const genericSchema = Yup.object().shape({
    codigo: Yup.string().required('Código es requerido'),
    descripcion: Yup.string().required('Descripción es requerido.'),
    order: Yup.number().min(1, 'Orden es requerido.'),
  });

  const formGeneric = useFormik({
    initialValues: initValues,
    validationSchema: genericSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      setTimeout(() => {
        if (values.id > 0) {
          updValueConditions(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              formGeneric.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err: any) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        } else {
          addValueConditions(values)
            .then((res) => {
              formGeneric.resetForm();
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              setLoading(false);
              handleClose(true);
            })
            .catch((err: any) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        }
      }, 1000);
    },
  });

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-650px"
      show={show}
      onHide={() => {
        formGeneric.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formGeneric.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 ">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formGeneric.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y pe-7"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              {formGeneric.status && (
                <div className="alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formGeneric.status}
                  </div>
                </div>
              )}
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Código</label>
                  {formGeneric.touched.codigo && formGeneric.errors.codigo && (
                    <div className="fv-plugins-message-container text-danger w-100 ms-5">
                      <span role="alert">{formGeneric.errors.codigo}</span>
                    </div>
                  )}
                </div>
                <input
                  disabled={id_row > 0 ? true : false}
                  placeholder="Código"
                  {...formGeneric.getFieldProps('codigo')}
                  maxLength={20}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="codigo"
                  autoComplete="off"
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">
                    Descripción
                  </label>
                  {formGeneric.touched.descripcion &&
                    formGeneric.errors.descripcion && (
                      <div className="fv-plugins-message-container text-danger w-100 ms-5">
                        <span role="alert">
                          {formGeneric.errors.descripcion}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Descripción"
                  {...formGeneric.getFieldProps('descripcion')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="descripcion"
                  maxLength={150}
                  autoComplete="off"
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Orden</label>
                  {formGeneric.touched.order && formGeneric.errors.order && (
                    <div className="fv-plugins-message-container text-danger w-100 ms-5">
                      <span role="alert">{formGeneric.errors.order}</span>
                    </div>
                  )}
                </div>
                <NumericFormat
                  disabled={id_row > 0 ? true : false}
                  className="form-control mb-3 mb-lg-0"
                  thousandSeparator={false}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                  allowNegative={false}
                  decimalScale={0}
                  value={formGeneric.values.order}
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (!value) {
                      e.target.value = '0';
                    }
                  }}
                  isAllowed={(values: any) => {
                    const { floatValue } = values;
                    const integerPart = Math.floor(floatValue);
                    return integerPart.toString().length <= 10;
                  }}
                  suffix=""
                  allowLeadingZeros={false}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    formGeneric.setFieldValue('order', floatValue);
                  }}
                />
              </div>
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formGeneric.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && (
                  <span className="indicator-label">
                    {id_row > 0 ? 'Actualizar' : 'Guardar'}
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
