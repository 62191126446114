import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { SimpleAlert } from '../../../../_metronic/helpers';
import { useUpdateConditionsAjuste } from '../hooks/ConditionsHook';

type Props = {
  html: string;
};

const ConditionsSection: React.FC<Props> = ({ html }) => {
  const [content, setContent] = useState<string>('');
  // const [loading, setLoading] = useState<boolean>(false);
  const { updateConditions, loading } = useUpdateConditionsAjuste();

  const saveContent = async (e: any) => {
    e.preventDefault();
    await updateConditions(content);
  };

  useEffect(() => {
    if (html) {
      setContent(html);
    }
  }, [html]);

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-12">
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column">
                <div className="space-items-between">Template</div>
              </div>
            </div>
            <div className="card-body border-top p-9">
              <div className="row">
                <div className="col h-auto">
                  <ReactQuill
                    style={{
                      borderRadius: '2px',
                    }}
                    className="h-300px"
                    value={content}
                    onChange={setContent}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-primary me-3 mt-20"
                      disabled={loading}
                      onClick={saveContent}
                    >
                      {!loading && (
                        <span className="indicator-label">
                          Guardar template
                        </span>
                      )}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export { ConditionsSection };
