import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { ListGrid } from './ListGrid';
import { Link } from 'react-router-dom';
import { useObtenerProveedores } from '../hooks/SuppliersHook';
import { ListaTarjeta } from './ListaTarjeta';
import { FiltrosProveedores } from './Filter';

const View: React.FC = () => {
  const [secction, setSecction] = useState<string | 'BOARD' | 'LIST'>('LIST');
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [actualizarProveedores, setActualizarProveedores] = useState<number>(
    Math.random() * 40
  );
  const [initFilterValues, setInitFilterValues] = useState({
    campo_id: null,
    valor: null,
  });
  const { data, loadingRequest } = useObtenerProveedores(
    actualizarProveedores,
    initFilterValues
  );

  const onCleanFilter = () => {
    setInitFilterValues({
      campo_id: null,
      valor: null,
    });
    setActualizarProveedores(Math.random() * 40);
  };

  const onSearchFilter = (values: any) => {
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({
        campo_id: null,
        valor: null,
      });
    }
    setActualizarProveedores(Math.random() * 40);
  };

  const onRefreshDataProducts = () => {
    setActualizarProveedores(Math.random() * 100);
  };

  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  const toggleMode = (mode: 'LIST' | 'BOARD') => {
    localStorage.setItem('viewMode', mode);
    setSecction(mode);
  };
  useEffect(() => {
    const storedMode = localStorage.getItem('viewMode');
    if (storedMode) {
      setSecction(storedMode);
    }
  }, []);

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
          <div className="card card-flush p-5">
            <div className="row">
              <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                  <li className="nav-item cursor-pointer ">
                    <a
                      className={`nav-link me-6 ${
                        secction === 'LIST' ? 'text-active-primary active' : ''
                      } `}
                      onClick={() => {
                        toggleMode('LIST');
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/text/txt001.svg"
                        className="svg-icon-1 fs-7"
                      />
                      <span className="margin-left-05">Lista</span>
                    </a>
                  </li>
                  <li className="nav-item cursor-pointer">
                    <a
                      className={`nav-link me-6  ${
                        secction === 'BOARD' ? 'text-active-primary active' : ''
                      }`}
                      onClick={() => {
                        toggleMode('BOARD');
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/layouts/lay002.svg"
                        className="svg-icon-1 fs-7"
                      />
                      <span className="margin-left-05">Tarjetas</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4 position-end">
                <div className="card-title center-items">
                  <Link
                    className="btn btn-sm btn-icon btn-light-primary border-btn"
                    to={'/cotizaciones/proveedores/add/'}
                  >
                    <KTSVG
                      path="/media/icons/duotune/abstract/abs011.svg"
                      className="svg-icon-5 svg-icon-gray-500 "
                    />
                  </Link>
                  <div className="mr-05"></div>

                  <FiltrosProveedores
                    onCleanFilter={onCleanFilter}
                    onSearchFilter={onSearchFilter}
                    initFilterValues={initFilterValues}
                  ></FiltrosProveedores>
                </div>

                <div className="mr-05"></div>
              </div>
            </div>
            <div className="row">
              {secction === 'BOARD' && (
                <ListaTarjeta
                  board={data}
                  onRefreshData={onRefreshDataProducts}
                />
              )}
              {secction === 'LIST' && (
                <ListGrid data={data} loadingGrid={loadingGrid} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
