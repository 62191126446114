import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { encryptId, KTSVG } from '../../../../_metronic/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { styleSelect } from '../../../../_metronic/helpers/SelectStyles';

import {
  useCreateQuoNew,
  useGetDataSelectedByFieldQuotations,
  useGetFieldsNewQuotations,
} from '../../cotizacion/hooks/QuotationHook';
import {
  useGetAllQuo,
  useGetAllQuoNew,
} from '../../cotizacion/hooks/ProductsHook';

type Props = {
  oportunidad_id: number;
  show: boolean;
  handleClose: (updateData: boolean) => void;
};

const scheme = Yup.object().shape({
  existente: Yup.boolean().required('La propiedad existente es requerida.'),
  cotizacion_id: Yup.number().when('existente', {
    is: true,
    then: Yup.number()
      .min(1, 'Oportunidad es requerido')
      .required('Oportunidad es requerido'),
    otherwise: Yup.number().notRequired(),
  }),
});

const CreateQuoModal: React.FC<Props> = ({
  oportunidad_id,
  show,
  handleClose,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { listFields } = useGetFieldsNewQuotations();
  const [secondOption, setSecondOption] = useState(null);
  const [quoOption, setQuoOption] = useState(null);
  const [code, setCode] = useState<string>('');
  const { dataOptionSelected } = useGetDataSelectedByFieldQuotations(code);
  const [isFetch, setIsFetch] = useState<boolean>(false);

  const CodesForInput = ['COTI_NOM', 'COTI_CTA', 'COTI_OPO'];

  //DATA COTIZACIONES
  const [campoId, setCampoId] = useState<any>(null);
  const [value, setValue] = useState<any>(null);
  const [filterValues, setFilterValues] = useState({
    campo_id: null,
    valor: null,
  });
  const [reloadDataQuotations, setReloadDataQuotations] = useState<number>(
    Math.random() * 40
  );
  const { dataQuotations, loadingRequestQuotations } = useGetAllQuoNew(
    reloadDataQuotations,
    filterValues
  );
  const { createQuo } = useCreateQuoNew();

  const formFilterQuo = useFormik({
    initialValues: {
      cotizacion_id: 0,
      oportunidad_id: oportunidad_id,
      existente: false,
    },
    validationSchema: scheme,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(async () => {
        if (values.existente) {
          await createQuo(values.cotizacion_id, values.oportunidad_id);
          setLoading(false);
        } else {
          history.push(
            `/cotizaciones/add/${encryptId(values.oportunidad_id.toString())}`
          );
          setLoading(false);
        }
      }, 2000);
    },
  });

  const [selectedOption, setSelectedOption] = useState<string>('create');
  const [selectedOptionSearch, setSelectedOptionSearch] = useState(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = event.target.value;
    if (type) {
      setSelectedOption(type);
      formFilterQuo.setFieldValue(
        'existente',
        type === 'create' ? false : true
      );
      if (type === 'create') {
        formFilterQuo.setFieldValue('cotizacion_id', 0);
      }
    }
  };

  const cleanForm = () => {
    formFilterQuo.resetForm();
    setSelectedOption('create');
    setFilterValues({
      campo_id: null,
      valor: null,
    });
    handleClose(false);
    setSelectedOptionSearch(null);
    setCampoId(null);
    setSecondOption(null);
    setValue(null);
    setQuoOption(null);
  };

  useEffect(() => {
    if (code) {
      if (CodesForInput.includes(code)) {
        setIsFetch(true);
      }
    }
  }, [code]);

  const handleSelectFirst = (selectedOption: any) => {
    const { code, value } = selectedOption;
    setSelectedOptionSearch(selectedOption);
    setCampoId(value);
    setSecondOption(null);
    setValue(null);
    setCode(code);
    setQuoOption(null);
    formFilterQuo.setFieldValue('cotizacion_id', 0);
    setFilterValues({
      campo_id: null,
      valor: null,
    });
  };
  const handleSelectSecond = (selectedOption: any) => {
    const { code, value } = selectedOption;
    setSecondOption(selectedOption);
    setValue(value);
    setFilterValues({
      campo_id: campoId,
      valor: value,
    });
  };
  const handleSelectQuo = (selectedOption: any) => {
    const { value } = selectedOption;
    setQuoOption(selectedOption);
    formFilterQuo.setFieldValue('cotizacion_id', value);
  };

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog mw-900px"
      show={show}
      onHide={cleanForm}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="">
            ¿Deseas crear una copia de una cotización existente?
          </h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={cleanForm}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body mx-5 padding-top-00">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formFilterQuo.handleSubmit}
          >
            <div className="min-h-500px">
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                  <div className="mb-7 mt-10">
                    <div className="justify-content-center ">
                      <div className="d-flex fv-row">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input me-3"
                            type="radio"
                            id="create"
                            value="create"
                            checked={selectedOption === 'create'}
                            onChange={handleChange}
                          />
                          <label className="form-check-label" htmlFor="create">
                            <div className="fw-bolder text-gray-800">
                              Crear nueva cotización
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="my-5"></div>
                      <div className="d-flex fv-row">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input me-3"
                            type="radio"
                            id="relate"
                            value="relate"
                            checked={selectedOption === 'relate'}
                            onChange={handleChange}
                          />
                          <label className="form-check-label" htmlFor="relate">
                            <div className="fw-bolder text-gray-800">
                              Desde una cotización existente
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4"></div>
              </div>

              <div
                className="row wh-0100 mb-3"
                style={{
                  display: selectedOption === 'relate' ? 'block' : 'none',
                }}
              >
                <div className="separator my-5"></div>
                <div className="col-12">
                  <div className="mb-5">
                    <label className="form-label fw-bold">Buscar por: </label>
                    <div onClick={(e) => e.stopPropagation()}>
                      <Select
                        options={listFields}
                        placeholder={'Seleccione'}
                        onChange={(event) => {
                          handleSelectFirst(event);
                        }}
                        value={selectedOptionSearch}
                        noOptionsMessage={() => {
                          return <span>No se encontraron registros</span>;
                        }}
                        styles={styleSelect}
                      />
                    </div>
                  </div>
                  {isFetch ? (
                    <div className="mb-5">
                      <label className="form-label fw-bold">
                        Selecciona una opción:{' '}
                      </label>
                      <div onClick={(e) => e.stopPropagation()}>
                        <Select
                          options={dataOptionSelected}
                          placeholder={'Seleccione'}
                          onChange={(event) => {
                            handleSelectSecond(event);
                          }}
                          value={secondOption}
                          noOptionsMessage={() => {
                            return <span>No se encontraron registros</span>;
                          }}
                          styles={styleSelect}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="mb-5">
                    <label className="form-label fw-bold required">
                      Cotizaciones
                    </label>
                    <div onClick={(e) => e.stopPropagation()}>
                      <Select
                        value={quoOption}
                        options={dataQuotations}
                        styles={styleSelect}
                        placeholder={'Seleccione'}
                        isSearchable={true}
                        noOptionsMessage={() => {
                          return <span>{'No se encontraron registros'}</span>;
                        }}
                        onChange={(event) => {
                          handleSelectQuo(event);
                        }}
                        // isClearable
                        backspaceRemovesValue
                      />
                      {formFilterQuo.touched.cotizacion_id &&
                        formFilterQuo.errors.cotizacion_id && (
                          <div className="fv-plugins-message-container text-danger w-100 mt-2">
                            <span role="alert">
                              {formFilterQuo.errors.cotizacion_id}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={cleanForm}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary min-w-100px"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && (
                  <span className="indicator-label">
                    {selectedOption === 'create' ? 'Nueva' : 'Crear'}
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateQuoModal };
