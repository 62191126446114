import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { CreateModal } from '../_modals/CreateModal';
import { ListGrid } from './ListGrid';
import { RemoveModal } from '../_modals/RemoveModal';
import {
  useGetAllConditions,
  useGetConditionsHTML,
} from '../hooks/ConditionsHook';
import { ConditionsModel } from '../models/ConditionsModel';
import { ConditionsSection } from './ConditionsSection';

const View: React.FC = () => {
  const [visivilityRemoveModal, setVisivilityRemoveModal] = useState(false);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [register, setRegister] = useState<ConditionsModel>({
    id: 0,
    codigo: '',
    descripcion: '',
    order: 0,
  });

  // GRID
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [reloadConditions, setReloadConditions] = useState(Math.random() * 40);
  const [idRow, setIdRow] = useState<number>(0);

  const { data, loadingRequest } = useGetAllConditions(reloadGrid);
  const { html } = useGetConditionsHTML(reloadGrid);

  const addRegister = () => {
    setTitleModal('Agregar registro');
    setVisivilityModal(!visivilityModal);
  };
  const removeRegister = (row: any) => {
    setRegister(row);
    setVisivilityRemoveModal(!visivilityRemoveModal);
  };
  const editRegister = (id: number) => {
    setIdRow(id);
    setTitleModal('Editar registro');
    setVisivilityModal(!visivilityModal);
  };

  return (
    <>
      <ConditionsSection html={html} />
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column">
                <div className="space-items-between"></div>
              </div>

              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  onClick={() => {
                    addRegister();
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nuevo
                </a>
              </div>
            </div>

            <ListGrid
              data={data}
              loadingGrid={loadingRequest}
              onRemoveRegister={removeRegister}
              onEditRegister={editRegister}
            />
          </div>
        </div>
      </div>
      <CreateModal
        id_row={idRow}
        title={titleModal}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModal(!visivilityModal);
          setIdRow(0);
        }}
      ></CreateModal>
      <RemoveModal
        code_catalogo={''}
        show={visivilityRemoveModal}
        register={register}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityRemoveModal(!visivilityRemoveModal);
        }}
      ></RemoveModal>
    </>
  );
};

export { View };
