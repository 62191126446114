import React, { FC, useEffect, useState } from 'react';
import { formatDate } from '../../../../_metronic/helpers/FormatDate';
import { useHistory } from 'react-router-dom';
import { encryptId, formatPrice, KTSVG } from '../../../../_metronic/helpers';
import { formattedCurrency } from '../../../../_metronic/helpers/FormatCurrency';
import { restar, sumar } from '../../../../_metronic/helpers/PriceCalculations';

type Props = {
  board: any;
  onRefreshData: () => void;
};

const CardQuotation: FC<Props> = ({ board, onRefreshData }) => {
  const history = useHistory();
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(board.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, board.length);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [onRefreshData]);

  const calculateSubtotal = (
    subtotal: number,
    surcharge: number,
    discount: number,
    indirectos: number,
    utilidad: number
  ) => {
    let totalConSurcharge = sumar(subtotal, surcharge);
    let totalConDescuento = restar(totalConSurcharge, discount);
    let totalConIndirectos = sumar(totalConDescuento, indirectos);
    let total = sumar(totalConIndirectos, utilidad);
    return total;
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          {board.slice(startIndex, endIndex).map((item: any, index: number) => {
            return (
              <>
                <div
                  key={index}
                  className="col-md-6 col-lg-6 col-xxl-4 col-xl-6 mb-3"
                >
                  <div
                    className="card border h-100 cursor-pointer"
                    onClick={() => {
                      history.push(
                        `/cotizaciones/edit/${encryptId(item.id.toString())}`
                      );
                    }}
                  >
                    <div className="card-body d-flex flex-center flex-column p-5 ">
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        <div className="fw-bolder text-gray-700 fs-4 large-text w-100 pe-5">
                          {item.nombre}
                        </div>
                        <div className="symbol symbol-40px tooltip-soft">
                          {item.vendedorAsignado ? (
                            <>
                              {item.vendedorAsignado.user?.profile_photo ? (
                                <>
                                  <img
                                    src={`${process.env.REACT_APP_PUBLIC}users/${item?.vendedorAsignado.user?.profile_photo}`}
                                    crossOrigin="anonymous"
                                    className="symbol-label fs-8 "
                                    alt="user"
                                  />
                                  <span className="tooltiptext">{`${item?.vendedorAsignado?.nombre} ${item?.vendedorAsignado?.apellido_paterno}`}</span>
                                </>
                              ) : (
                                <>
                                  <span className="symbol-label fs-8 fw-bold bg-light-primary text-primary fw-bold">
                                    {`${item?.vendedorAsignado?.nombre[0]}${item?.vendedorAsignado?.apellido_paterno[0]}`}
                                  </span>
                                  <span className="tooltiptext">{`${item?.vendedorAsignado?.nombre} ${item?.vendedorAsignado?.apellido_paterno}`}</span>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="symbol-label">
                                <KTSVG
                                  path="/media/icons/duotune/communication/com006.svg"
                                  className="svg-icon-1"
                                />
                              </div>
                              <span className="tooltiptext">Sin vendedor</span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between w-100">
                        <div className="min-h-150px w-100">
                          <div className="text-gray-500 fw-bolder fs-7 mt-2">
                            Precio venta
                          </div>
                          <div className="text-gray-500 fs-5">
                            <span
                              className="fs-6 fw-bold"
                              style={{ color: '#1B84FF' }}
                            >
                              {`${formatPrice(
                                calculateSubtotal(
                                  Number(item.subtotal),
                                  Number(item.monto_recargo),
                                  Number(item.monto_descuento),
                                  Number(item.monto_costo_indirecto),
                                  Number(item.monto_utilidad)
                                ),
                                'es-MX',
                                'MXN',
                                2,
                                2
                              )}`}
                            </span>
                            <span className="fs-8 mt-3 ms-2">
                              {item.unidadMoneda && (
                                <>
                                  /{' '}
                                  <span data-kt-element="period">
                                    {item.unidadMoneda.code}
                                  </span>
                                </>
                              )}
                            </span>
                          </div>
                          <div className="text-gray-500  fs-7 mt-2 ">
                            <span className="fw-bolder">
                              Precio venta final
                            </span>
                            <span className="fs-8 mt-3 ms-2">
                              {Number(item.monto_impuestos) ? (
                                <>{'( c/impuesto)'}</>
                              ) : (
                                <>{''}</>
                              )}
                            </span>
                          </div>
                          <div className="text-gray-500  fs-5 ">
                            <span
                              className="fs-6 fw-bold"
                              style={{ color: '#1B84FF' }}
                            >
                              {`${`${formatPrice(
                                item.total ?? 0,
                                'es-MX',
                                'MXN',
                                2,
                                2
                              )}`}`}
                            </span>
                            <span className="fs-8 mt-3 ms-2">
                              {item.unidadMoneda && (
                                <>
                                  /{' '}
                                  <span data-kt-element="period">
                                    {item.unidadMoneda.code}
                                  </span>
                                </>
                              )}
                            </span>
                          </div>
                          <div className="w-100 d-flex mt-8">
                            <div className="w-50">
                              <span
                                className="fs-6 fw-bold"
                                style={{ color: '#1B84FF' }}
                              >
                                {item.versiones
                                  ? item.versiones.numero_version
                                  : 'R1'}
                              </span>
                              <span className="fs-8 text-gray-500 ms-5">
                                {formatDate(new Date(item.createdAt))}
                              </span>
                            </div>
                            <div className="w-50 text-end">
                              {item.estatusCotizacion && (
                                <>
                                  {item.estatusCotizacion.code === 'COTFN' ? (
                                    <>
                                      <div
                                        className={`badge badge-light-info `}
                                      >
                                        {item.estatusCotizacion.name}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className={`badge badge-light-success `}
                                      >
                                        En curso
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              // <div
              //   key={index}
              //   className="col-md-6 col-lg-6 col-xxl-4 col-xl-6 mb-4 mt-4"
              // >
              //   <div
              //     className="card border borders-card-board h-100 cursor-pointer"
              //     onClick={() =>
              //       history.push(
              //         `/cotizaciones/edit/${encryptId(item.id.toString())}`
              //       )
              //     }
              //   >
              //     <div className="card-body d-flex flex-center flex-column p-9">
              //       <div className="d-flex justify-content-between w-100 mb-5">
              //         <div>
              //           <a
              //             href="#"
              //             className="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0"
              //           >
              //             {item.nombre}
              //           </a>
              //           <div className="fw-bold text-gray-400 mb-6">
              //             {item.client}
              //           </div>
              //           <div className="fs-5 fw-bolder text-gray-700">
              //             {formattedCurrency(parseFloat(item.total))}
              //           </div>
              //         </div>
              //         <div
              //           className="symbol symbol-40px"
              //           style={{ borderRadius: '8px' }}
              //         >
              //           <span
              //             className={`symbol-label bg-light-primary text-primary" fs-5 fw-bolder`}
              //           >
              //             {item.vendedorAsignado
              //               ? `${item.vendedorAsignado?.nombre.substring(
              //                   0,
              //                   1
              //                 )}${item.vendedorAsignado?.apellido_paterno.substring(
              //                   0,
              //                   1
              //                 )}`
              //               : 'NA'}
              //           </span>
              //         </div>
              //       </div>
              //       <div className="d-flex justify-content-end w-100">
              //         <div className="rounded min-w-125px py-3 mx-3 px-4 mb-3">
              //           <div className="fw-bold text-gray-400">
              //             {formatDate(new Date(item.updatedAt))}
              //           </div>
              //         </div>
              //       </div>
              //     </div>
              //     <span className="position-absolute opacity-15 bottom-0 start-0 border-4 border-primary border-bottom w-100"></span>
              //   </div>
              // </div>
            );
          })}
        </div>
        <div className="row justify-content-center">
          <nav aria-label="Navegación de página">
            <ul className="pagination">
              {Array.from({ length: totalPages }, (_, i) => (
                <li
                  key={i}
                  className={`page-item ${
                    currentPage === i + 1 ? 'active' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => onPageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export { CardQuotation };
