import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import {
  formatPriceNew,
  KTSVG,
  SimpleAlert,
} from '../../../../../_metronic/helpers';
// import { useUpdateConditions } from '../../hooks/QuotationHook';
import { PaymentMethodsModel } from '../../models/QuotationModel';
import Select from 'react-select';
import { styleSelect } from '../../../../../_metronic/helpers/SelectStyles';
import { useSelectGenericPlus } from '../../../catalogos/generico/hooks/genericoHook';
import { NumericFormat } from 'react-number-format';
import {
  dividir,
  multiplicar,
  restar,
  sumar,
} from '../../../../../_metronic/helpers/PriceCalculations';
import {
  useGetInvoiceByQuoId,
  useUpdateScheduledInvoice,
} from '../../hooks/QuotationHook';

type Props = {
  id: number;
  total: number;
};

interface codeType {
  id: number;
  code: string;
  name: string;
}

const Invoicing: React.FC<Props> = ({ id, total }) => {
  const [reload, setReload] = useState(Math.random() * 40);

  const { paymentMethods, loading: loadingPM } = useGetInvoiceByQuoId(
    Number(id),
    reload
  );

  const [listPaymentMethods, setListPaymentMethods] =
    useState<PaymentMethodsModel[]>(paymentMethods);
  const [loading, setLoading] = useState('');
  const [totalPayment, setTotalPayment] = useState<number>(total);
  const { catalog: paymentType, loadingRequest: loadingPT } =
    useSelectGenericPlus('TIPO_PAGO');

  const { updateInvoice, loading: loadingInvoice } =
    useUpdateScheduledInvoice();

  const numeroRandom = (): number => {
    return Math.floor(10000 + Math.random() * 90000);
  };

  const addPayment = () => {
    const cleanedData = listPaymentMethods.filter(
      (item) => item.referencia !== 0
    );
    // let codeType = paymentType.find((item) => item.data.code === 'ANTPO');

    let totalResta = 0;
    let totalRow = 0;
    if (cleanedData.length === 0) {
      totalResta = total;
      totalRow = total;
    } else {
      totalResta = totalPayment;
      totalRow = totalPayment;
    }

    const newRow: PaymentMethodsModel = {
      id: 0,
      referencia: 0,
      pago: '',
      porcentaje: 0,
      monto_c_imp: 0,
      fecha: '',
      dias_pago: 0,
      vigencia: '',
      cotizacion_id: id,
      resta: totalResta,
      total: totalRow,
      tipo: {
        id: 0,
        name: '',
        code: '',
      },
    };
    setListPaymentMethods([...cleanedData, newRow]);
  };

  const paymentChange = (referencia: number, text: string) => {
    const newData = listPaymentMethods.map((item) =>
      item.referencia === referencia ? { ...item, pago: text } : item
    );
    setListPaymentMethods(newData);
  };

  const percentageChange = (referencia: number, percentage: number) => {
    let percentageDecimal = dividir(percentage, 100);
    setListPaymentMethods((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) =>
        item.referencia === referencia
          ? {
              ...item,
              porcentaje: percentage,
              monto_c_imp: multiplicar(
                Number(item.total),
                Number(percentageDecimal)
              ),
              resta: restar(
                item.total,
                multiplicar(Number(item.total), Number(percentageDecimal))
              ),
            }
          : item
      );
      // calculateData(referencia);
      return updatedDataMemo;
    });
  };

  const costChange = (referencia: number, monto_c_imp: number) => {
    setListPaymentMethods((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) =>
        item.referencia === referencia
          ? {
              ...item,
              monto_c_imp: monto_c_imp,
              resta: restar(item.total, monto_c_imp),
            }
          : item
      );
      // calculateData(referencia);
      return updatedDataMemo;
    });
  };
  const dateChange = (referencia: number, date: string) => {
    setListPaymentMethods((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) =>
        item.referencia === referencia
          ? {
              ...item,
              fecha: date,
              vigencia: addDaysToDate(date, item.dias_pago),
            }
          : item
      );
      return updatedDataMemo;
    });
  };
  const payDaysChange = (referencia: number, days: number) => {
    setListPaymentMethods((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) =>
        item.referencia === referencia
          ? {
              ...item,
              dias_pago: days,
              vigencia: addDaysToDate(item.fecha, days),
            }
          : item
      );
      return updatedDataMemo;
    });
  };

  const saveRow = (referencia: number, row: any) => {
    let codeType: any = {};
    if (listPaymentMethods.length === 1) {
      codeType = paymentType.find((item) => item.data.code === 'ANTPO');
    } else {
      codeType = paymentType.find((item) => item.data.code === 'SUBSC');
    }
    let calculate = restar(totalPayment, row.monto_c_imp);

    if (calculate === 0) {
      codeType = paymentType.find((item) => item.data.code === 'CIERRE');
    }

    if (row.pago === '') {
      SimpleAlert('Favor de agregar el nombre del pago.', 3000, 'error');
      return;
    }
    if (row.porcentaje === 0 && row.monto_c_imp === 0) {
      SimpleAlert('Favor de agregar monto o porcentaje.', 3000, 'error');
      return;
    }
    if (row.fecha === '') {
      SimpleAlert('Favor de agregar fecha de pago.', 3000, 'error');
      return;
    }

    setListPaymentMethods((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) =>
        item.referencia === referencia
          ? {
              ...item,
              referencia: numeroRandom(),
              tipo: codeType
                ? {
                    id: codeType.id,
                    name: codeType.label,
                    code: codeType.data.code,
                  }
                : { id: 0, name: '', code: '' },
            }
          : item
      );
      return updatedDataMemo;
    });
    setTotalPayment(calculate);
    SimpleAlert('Se agregó el registro exitosamente.', 3000, 'success');
    return;
  };

  const deleteRowByRef = (referencia: number, monto: number) => {
    let calculate = sumar(totalPayment, monto);
    setTotalPayment(calculate);
    setListPaymentMethods((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.filter(
        (item) => item.referencia !== referencia
      );
      return updatedDataMemo;
    });
  };

  const saveInvoice = async (e: any) => {
    e.preventDefault();
    let count = listPaymentMethods.filter((item) => item.referencia !== 0);
    if (count.length) {
      await updateInvoice(id, count);
    }
  };

  const addDaysToDate = (date: string, days: number) => {
    if (!date) return '';
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    const formattedDate = newDate.toISOString().split('T')[0];
    return formattedDate;
  };

  useEffect(() => {
    if (paymentMethods.length > 0) {
      setListPaymentMethods(paymentMethods);
      let substraction = paymentMethods.reduce(
        (sum, item) => sum + (Number(item.monto_c_imp) || 0),
        0
      );
      setTotalPayment(restar(total, substraction));
    }
    return () => {
      setListPaymentMethods([]);
    };
  }, [paymentMethods]);

  return (
    <>
      <div className="card mb-6 mb-xl-9">
        <div className="card-header">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Facturación programada</h3>
          </div>
        </div>
        <div className="card-body border-top p-9">
          <div className="row">
            <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y">
              <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
                <thead>
                  <tr className="text-gray-800 fs-7">
                    <th className="w-100px text-center">ID</th>
                    <th className="w-150px">Tipo</th>
                    <th className="w-250px">Pago</th>
                    <th className="w-100px">%</th>
                    <th className="w-150px">Monto c/imp.</th>
                    <th className="w-150px">Resta</th>
                    <th className="w-100px">Fecha</th>
                    <th className="w-150px">Días Pago</th>
                    <th className="w-100px">Vigencia</th>
                    <th className="w-100px"></th>
                  </tr>
                </thead>
                <tbody className="">
                  {listPaymentMethods.length > 0 ? (
                    listPaymentMethods.map((row, index) => (
                      <tr key={row.id}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 text-gray-600  ms-5">
                              {index + 1}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                              <>{row.tipo.name}</>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                              <input
                                type="text"
                                className="form-control w-200px"
                                value={row.pago}
                                maxLength={50}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  paymentChange(row.referencia, value || '');
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                              <NumericFormat
                                type="text"
                                autoComplete="off"
                                className="form-control w-100px"
                                value={row.porcentaje}
                                decimalScale={0}
                                decimalSeparator="."
                                fixedDecimalScale
                                placeholder={'0 %'}
                                disabled={row.referencia > 0}
                                suffix={' %'}
                                isAllowed={(values: any) => {
                                  const { floatValue } = values;
                                  let allow =
                                    floatValue <= 100 ||
                                    floatValue === undefined;
                                  return allow;
                                }}
                                allowNegative={false}
                                thousandSeparator={''}
                                onValueChange={(values) => {
                                  const { floatValue } = values;
                                  percentageChange(
                                    row.referencia,
                                    floatValue || 0
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                              <NumericFormat
                                className="form-control w-150px"
                                thousandSeparator={true}
                                disabled={
                                  row.porcentaje > 0 || row.referencia > 0
                                }
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                allowLeadingZeros={false}
                                isAllowed={(values: any) => {
                                  const floatValue = values.floatValue ?? 0;
                                  return floatValue <= row.total;
                                }}
                                // isAllowed={(values: any) => {
                                //   const { floatValue } = values;
                                //   const integerPart = Math.floor(floatValue);
                                //   return integerPart.toString().length <= 9;
                                // }}
                                value={row.monto_c_imp}
                                onValueChange={(values: any) => {
                                  const { floatValue } = values;
                                  costChange(row.referencia, floatValue || 0);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 fw-bold text-gray-600">
                              {`${formatPriceNew(
                                row.resta,
                                'es-MX',
                                'MXN',
                                2,
                                2
                              )}`}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                              <input
                                className={'form-control '}
                                data-kt-element="input"
                                type="date"
                                name="fecha_inicio"
                                value={row.fecha}
                                onChange={(e) => {
                                  const date = e.target.value;
                                  dateChange(row.referencia, date || '');
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                              <NumericFormat
                                type="text"
                                autoComplete="off"
                                className="form-control w-100px"
                                disabled={row.fecha ? false : true}
                                value={row.dias_pago}
                                decimalScale={0}
                                decimalSeparator="."
                                fixedDecimalScale
                                placeholder={'0'}
                                suffix={''}
                                isAllowed={(values: any) => {
                                  const { floatValue } = values;
                                  let allow =
                                    floatValue <= 100 ||
                                    floatValue === undefined;
                                  return allow;
                                }}
                                allowNegative={false}
                                thousandSeparator={''}
                                onValueChange={(values) => {
                                  const { floatValue } = values;
                                  payDaysChange(
                                    row.referencia,
                                    floatValue || 0
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                              <input
                                className={'form-control'}
                                data-kt-element="input"
                                type="date"
                                disabled={true}
                                name="fecha_inicio"
                                value={row.vigencia}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                              {row.referencia > 0 &&
                                index === listPaymentMethods.length - 1 && (
                                  <>
                                    <div
                                      className="badge badge-light-danger text-hover-danger fw-bold px-4 py-3 cursor-pointer"
                                      title="Eliminar"
                                      onClick={async (event) => {
                                        deleteRowByRef(
                                          row.referencia,
                                          row.monto_c_imp
                                        );
                                      }}
                                    >
                                      <KTSVG
                                        path="/media/icons/duotune/general/gen027.svg"
                                        className="svg-icon-4"
                                      />
                                    </div>
                                  </>
                                )}

                              {row.referencia === 0 && (
                                <>
                                  <div
                                    className="badge badge-light-primary  text-hover-primary fw-bold px-4 py-3 cursor-pointer"
                                    title="Guardar"
                                    onClick={async (event) => {
                                      saveRow(row.referencia, row);
                                    }}
                                  >
                                    <KTSVG
                                      path="/media/icons/duotune/general/gen058.svg"
                                      className="svg-icon-4"
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center text-muted">
                        Sin conceptos
                      </td>
                    </tr>
                  )}
                  {totalPayment > 0 && (
                    <>
                      <tr>
                        <td colSpan={10}>
                          <div className="d-flex align-items-center">
                            <div
                              className="btn btn-light-primary btn-sm mb-10"
                              onClick={addPayment}
                            >
                              <KTSVG
                                path="/media/icons/duotune/arrows/arr075.svg"
                                className="svg-icon-5"
                              />
                              Agregar
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary me-3 mt-20"
                  disabled={loadingInvoice}
                  onClick={saveInvoice}
                >
                  {!loadingInvoice && (
                    <span className="indicator-label">Guardar</span>
                  )}
                  {loadingInvoice && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoicing;
