import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { SimpleAlert } from '../../../../../_metronic/helpers';
import { useUpdateConditions } from '../../hooks/QuotationHook';

type Props = {
  id: number;
  condiciones_comerciales: string;
  moneda: string;
  isVerify: boolean;
  activeConditions: (active: boolean) => void;
  currentConditions: (conditions: string) => void;
};

const Comments: React.FC<Props> = ({
  id,
  condiciones_comerciales,
  moneda,
  isVerify,
  activeConditions,
  currentConditions,
}) => {
  const [content, setContent] = useState<string>(condiciones_comerciales);
  const { updateConditions, loading } = useUpdateConditions();

  const saveContent = async (e: any) => {
    e.preventDefault();

    if (!isVerify) {
      SimpleAlert(
        'Por favor, marca la casilla para verificar las condiciones comerciales',
        3000,
        'error'
      );
      return false;
    }
    if (content) {
      await updateConditions(id, content, isVerify);
    }
  };

  // useEffect(() => {
  //   let content = ``;
  //   if (condiciones_comerciales) {
  //     setContent(condiciones_comerciales);
  //     currentConditions(condiciones_comerciales);
  //   } else {
  //     setContent(content);
  //     currentConditions(condiciones_comerciales);
  //   }
  // }, [moneda, condiciones_comerciales]);

  return (
    <>
      <div className="card mb-6 mb-xl-9">
        <div className="card-header">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Condiciones comerciales</h3>
          </div>
        </div>
        <div className="card-body border-top p-9">
          <div className="row">
            <div className="col h-auto">
              <ReactQuill
                style={{
                  borderRadius: '2px',
                }}
                className="h-600px"
                value={content}
                // onChange={setContent}
                onChange={(value) => {
                  setContent(value);
                  currentConditions(value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col  mt-20">
              <label className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input mr-10"
                  type="checkbox"
                  checked={isVerify}
                  onChange={(event: any) => {
                    activeConditions(event.target.checked);
                  }}
                />
                Confirmo que he revisado las condiciones comerciales
              </label>
            </div>
            <div className="col">
              <div className="d-flex justify-content-end">
                {/* <button
                  className="btn btn-primary me-3 mt-20"
                  onClick={saveContent}
                >
                  Guardar
                </button> */}

                <button
                  type="button"
                  className="btn btn-primary me-3 mt-20"
                  disabled={loading}
                  onClick={saveContent}
                >
                  {!loading && (
                    <span className="indicator-label">Guardar condiciones</span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comments;
