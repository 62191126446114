import { useState, useEffect } from "react";
import { ConditionsModel } from "../models/ConditionsModel";
import { useHistory } from "react-router-dom";
import { getConditions, getDataCinditionById, getHTMLConditions, updateConditionsAjustes } from "../../../service/conditions/Conditions";
import { SimpleAlert } from "../../../../_metronic/helpers";

export const useGetAllConditions = (loading: number) => {
  const [count, setCount] = useState(1);
  const [data, setData] = useState<ConditionsModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      const data = await getConditions()
        .then()
        .catch((error) => {
          setLoadingRequest(false);

          history.push("/error/500");
        });
      if (data) {
        setCount(data);
        setData(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
  }, [loading]);
  return { data, count, loadingRequest };
};

export const useGetOne = (id: number) => {
  const initModel = {
    id: 0,
    codigo: "",
    descripcion: "",
    order: 0,
  };
  const [initValues, setInitValues] = useState<ConditionsModel>(initModel);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getDataCinditionById(id)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
  }, [id]);

  return { initValues };
};


export const useGetConditionsHTML = (reload: number) => {
  const [html, setHTML] = useState<string>("");
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      const data = await getHTMLConditions()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        if(data.condiciones_comerciales){
          setHTML(data.condiciones_comerciales);

        }
      }
    };
    fetchPost();
  }, []);
  return { html };
};

export const useUpdateConditionsAjuste = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();
  const updateConditions = async (conditions: string) => {
    setLoading(true);
    try {
      const res = await updateConditionsAjustes(conditions);
      setLoading(false);
      SimpleAlert(res.data.message, 3000, "success");
    } catch (error: any) {
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, "error");
        setLoading(false);
      } else {
        history.push("/error/500");
      }
    }
  };
  return { updateConditions, loading };
};
