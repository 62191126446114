import axios from "axios";
const API_URL = process.env.REACT_APP_API;

export async function getConditions() {
    try {
        const response = await axios.get(`${API_URL}condiciones/comerciales`);
        const data = response.data.doc.data;
        return data;
    } catch (error) {
        console.error("Error en la petición:", error);
        throw error; 
    }
  }

  export async function getDataCinditionById(id: number) {
    try {
        const response = await axios.get(`${API_URL}condiciones/comerciales/${id}`);
        return response.data.doc.data;
    } catch (error) {
        console.error("Error en la petición:", error);
        throw error; 
    }
  }

  export const updateConditionsAjustes = async (conditions: string) => {
    try {
      let response = await axios.put(`${API_URL}catalogos/empresas/condiciones/comerciales`, {
        condiciones_comerciales: conditions,
      });
      return response;
    } catch (error) {
      console.error("Error al actualizar las condiciones comerciales:", error);
      throw error;
    }
  };


  export async function getHTMLConditions() {
    try {
        const response = await axios.get(`${API_URL}catalogos/empresas/condiciones/comerciales`);
        return response.data.doc.data;
    } catch (error) {
        console.error("Error en la petición:", error);
        throw error; 
    }
  }

  export async function addValueConditions(body: any) {
    try {
        const { codigo, descripcion, order } = body;
        const response = await axios.post(`${API_URL}condiciones/comerciales`, {
            codigo,
            descripcion,
            order
          });
          return response;
    } catch (error) {
        console.error("Error en la petición:", error);
        throw error; 
    } 
  }
  
  export async function updValueConditions(body: any) {
    try {
        const { id, codigo, descripcion, order } = body;
        const response = await axios.put(`${API_URL}condiciones/comerciales/${id}`, {
            codigo,
            descripcion,
            order
          });
          return response;
    } catch (error) {
        console.error("Error en la petición:", error);
        throw error; 
    } 
  }

  export async function removeRegisterConditions(id: number) {
    try {
        const response = await axios.delete(`${API_URL}condiciones/comerciales/${id}`);
        return response;
    } catch (error) {
        console.error("Error en la petición:", error);
        throw error; 
    }  
  }
  